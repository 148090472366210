import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="pt-6 pb-5 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="widget-title">
                  <b>Μορφή:</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Το «Βαρβάκειον Ίδρυμα» είναι ΝΠΔΔ, που συστήθηκε με το Ν.Δ.
                    2562/1953 (ΦΕΚ 240 Α΄). Εποπτεύεται από το Υπουργείο
                    Οικονομικών, ως κληροδότημα κοινωφελούς σκοπού διεπόμενο από
                    τις διατάξεις του Ν.4182/2013 (ΦΕΚ 185 Α΄) και
                    συνεποπτεύεται από το Υπουργείο Παιδείας και Θρησκευμάτων,
                    λόγω του εκπαιδευτικού σκοπού του.
                  </li>
                  <li className="mb-1">
                    <b>
                      Το Ίδρυμα δεν επιχορηγείται ή χρηματοδοτείται από το
                      Δημόσιο ή την Ευρωπαϊκή Ένωση.
                    </b>
                  </li>
                </ol>
                <h3 className="widget-title">
                  <b>Έδρα:</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Η υπηρεσία του Ιδρύματος στεγάζεται σε ιδιόκτητα γραφεία
                    στον 4ο όροφο του μεγάρου επί της οδού Ιπποκράτους 2 και
                    Ακαδημίας, στην Αθήνα.
                  </li>
                </ol>
                <h3 className="widget-title mt-3">
                  <b>Νομικό Πλαίσιο:</b>
                </h3>
                <ul>
                  <li>
                    Η νομοθεσία που διέπει το Βαρβάκειο Ίδρυμα είναι η εξής:
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Ν.4182/2013 (ΦΕΚ 185 Α΄) «Κώδικας κοινωφελών περιουσιών,
                    σχολαζουσών κληρονομιών και λοιπές διατάξεις», όπως ισχύει.
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Ν.Δ.2562/1953 (Φ.Ε.Κ. 240 Α΄) «Περί συστάσεως Βαρβακείου
                    Ιδρύματος».
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Β.Δ. της 17/2/1956 (ΦΕΚ 76 Α΄) «Περί του τρόπου διοικήσεως
                    και διαχειρίσεως της περιουσίας του Βαρβακείου Ιδρύματος».
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Π.Δ. 690/1974 (ΦΕΚ 298 Α΄) «Περί τροποποιήσεως του από
                    17/2/56 Β.Δ/τος».
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Ν.725/1977 (ΦΕΚ 300 Α΄) «Περί συνθέσεως του Δ.Σ. του
                    Βαρβακείου Ιδρύματος και άλλων τινών διατάξεων».
                  </li>
                  <li>
                    <i
                      className="icon-angle-right mr-2"
                      style={{
                        color: "#3333ff",
                        fontSize: "10px",
                      }}
                    ></i>
                    Κ.Υ.Α.1114515/4165/0001/27-10-89 (ΦΕΚ 840 Β΄, όπως
                    διορθώθηκε στο ΦΕΚ 875 Β΄) «Αντιστοίχιση Υπηρεσιακής Μονάδας
                    του Βαρβακείου Ιδρύματος με εκείνες του Ν.1586/1986 και
                    καθορισμός κλάδων, των οποίων οι υπάλληλοι κρίνονται για
                    κάλυψη θέσεων προϊσταμένων».
                  </li>
                </ul>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
