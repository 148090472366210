import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <footer className="footer footer-2">
      <div className="footer-middle pt-1">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <blockquote className="testimonial text-center">
                <img
                  src={urlHelper.createAccountImageUrl(
                    account,
                    repository,
                    "item",
                    "gallery",
                    "default",
                    "varvakis-small"
                  )}
                  alt="user"
                />
                <p style={{ color: "#fefefe" }}>
                  “ Επειδή δια να ανακαλεσθώσιν αι τέχναι και αι επιστήμαι εις
                  την Ελλάδα, ανάγκη πάσα να ευρεθή μόνιμος και διαμένων
                  πόρος... δι' εν κεντρικόν εθνικόν σχολείον... αφιερώ ρούβλια
                  300.000 ”
                </p>

                <cite style={{ color: "#fefefe" }}>
                  Ιωάννης Βαρβάκης
                  <span>(22/5/1824)</span>
                </cite>
              </blockquote>
            </div>
            {/* End .col-sm-12 col-lg-3 */}

            <div className="col-12" style={{ height: "auto" }}>
              <ul>
                <li className="li_xs_fix">
                  <a href="http://www.et.gr/" target="_blank" rel="noreferrer">
                    Εθνικό Τυπογραφείο
                  </a>
                </li>
                <li className="li_xs_fix">
                  <a
                    href="http://ilektronikomouseio.varvakeionidryma.gr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ηλεκτρονικό Μουσείο
                  </a>
                </li>
                <li className="li_xs_fix">
                  <a
                    href="http://varvakis.varvakeionidryma.gr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ιωάννης Βαρβάκης
                  </a>
                </li>
              </ul>
            </div>
            {/* End .col-sm-4 col-lg-3 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-middle */}

      <div className="footer-bottom">
        <div className="container">
          <p className="footer-copyright" style={{ color: "#fefefe" }}>
            Copyright &copy; 2022 Varvakeionidryma | All Rights Reserved
          </p>

          {/* <div className="social-icons">
            <span className="social-label">Social Media</span>

            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div> */}
          {/* End .soial-icons */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-bottom */}
    </footer>
  );
};

export default ExportDefault;
