import Home from "./Page/Home";
import Profile from "./Page/Profile";
import History from "./Page/History";
import Purpose from "./Page/Purpose";
import Contact from "./Page/Contact";
import Results from "./Page/Results";
import KlirodotimaHistory from "./Page/KlirodotimaHistory";
import Management from "./Page/Management";
import Scholarships from "./Page/Scholarships";
import KlirodotimaDecisions from "./Page/KlirodotimaDecisions";
import ManagementDecisions from "./Page/ManagementDecisions";
import News from "./Page/News";
import KlirodotimaPurpose from "./Page/KlirodotimaPurpose";

const Module = {
  Home,
  Profile,
  Contact,
  Results,
  KlirodotimaHistory,
  Management,
  Scholarships,
  KlirodotimaDecisions,
  ManagementDecisions,
  News,
  KlirodotimaPurpose,
  History,
  Purpose,
};

export default Module;
