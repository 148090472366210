import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const devHelper = component.getHelpers("dev");

  const items = component.getData("default.items", []);

  items.sort((a, b) => {
    return new Date(b.published).getTime() - new Date(a.published).getTime();
  });

  // console.log(items);
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-12 mb-3" style={{ textAlign: "center" }}>
              <h3>
                <b>Αποφάσεις Κληροδοτήματος Σαρή</b>
              </h3>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <h3 style={{ marginLeft: "20px" }}>
                  <b>Ισολογισμοί</b>
                </h3>
                <ul>
                  {items &&
                    items.map(
                      (item, index) =>
                        item.file_categories.length !== 0 &&
                        item.file_categories[0].item._values.slug === "sari" &&
                        item.file_categories[0]["balance-sheet"] && (
                          <li
                            key={`item-${devHelper.getObjectValue(
                              item,
                              "slug"
                            )}`}
                          >
                            <i
                              className="icon-angle-right mr-2"
                              style={{
                                color: "#3333ff",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            ></i>
                            <a
                              href={urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "file"
                              )}
                              target="_blank"
                              rel="noreferrer"
                              className="file_link"
                            >
                              {component.ucfirst(
                                devHelper.getObjectValue(item, "name")
                              )}
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <h3 style={{ marginLeft: "20px" }}>
                  <b>Προϋπολογισμοί</b>
                </h3>
                <ul>
                  {items &&
                    items.map(
                      (item, index) =>
                        item.file_categories.length !== 0 &&
                        item.file_categories[0].item._values.slug === "sari" &&
                        item.file_categories[0].budgets && (
                          <li
                            key={`item-${devHelper.getObjectValue(
                              item,
                              "slug"
                            )}`}
                          >
                            <i
                              className="icon-angle-right mr-2"
                              style={{
                                color: "#3333ff",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            ></i>
                            <a
                              href={urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "file"
                              )}
                              target="_blank"
                              rel="noreferrer"
                              className="file_link"
                            >
                              {component.ucfirst(
                                devHelper.getObjectValue(item, "name")
                              )}
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <h3 style={{ marginLeft: "20px" }}>
                  <b>Απολογισμoί</b>
                </h3>
                <ul>
                  {items &&
                    items.map(
                      (item, index) =>
                        item.file_categories.length !== 0 &&
                        item.file_categories[0].item._values.slug === "sari" &&
                        item.file_categories[0].accounts && (
                          <li
                            key={`item-${devHelper.getObjectValue(
                              item,
                              "slug"
                            )}`}
                          >
                            <i
                              className="icon-angle-right mr-2"
                              style={{
                                color: "#3333ff",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            ></i>
                            <a
                              href={urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "file"
                              )}
                              target="_blank"
                              rel="noreferrer"
                              className="file_link"
                            >
                              {component.ucfirst(
                                devHelper.getObjectValue(item, "name")
                              )}
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <h3 style={{ marginLeft: "20px" }}>
                  <b>Ισοζύγια</b>
                </h3>
                <ul>
                  {items &&
                    items.map(
                      (item, index) =>
                        item.file_categories.length !== 0 &&
                        item.file_categories[0].item._values.slug === "sari" &&
                        item.file_categories[0].balance && (
                          <li
                            key={`item-${devHelper.getObjectValue(
                              item,
                              "slug"
                            )}`}
                          >
                            <i
                              className="icon-angle-right mr-2"
                              style={{
                                color: "#3333ff",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            ></i>
                            <a
                              href={urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "file"
                              )}
                              target="_blank"
                              rel="noreferrer"
                              className="file_link"
                            >
                              {component.ucfirst(
                                devHelper.getObjectValue(item, "name")
                              )}
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <h3 style={{ marginLeft: "20px" }}>
                  <b>Αλλα</b>
                </h3>
                <ul>
                  {items &&
                    items.map(
                      (item, index) =>
                        item.file_categories.length !== 0 &&
                        item.file_categories[0].item._values.slug === "sari" &&
                        !item.file_categories[0].accounts &&
                        !item.file_categories[0]["balance-sheet"] &&
                        !item.file_categories[0].balance &&
                        !item.file_categories[0].budgets && (
                          <li
                            key={`item-${devHelper.getObjectValue(
                              item,
                              "slug"
                            )}`}
                          >
                            <i
                              className="icon-angle-right mr-2"
                              style={{
                                color: "#3333ff",
                                fontSize: "15px",
                                fontWeight: "600",
                              }}
                            ></i>
                            <a
                              href={urlHelper.createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(item, "slug"),
                                "default",
                                "file"
                              )}
                              target="_blank"
                              rel="noreferrer"
                              className="file_link"
                            >
                              {component.ucfirst(
                                devHelper.getObjectValue(item, "name")
                              )}
                            </a>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
