import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <Router>
        <Switch>
          <Route exact path="/" component={Pages.Home} />
          <Route exact path="/profile" component={Pages.Profile} />
          <Route exact path="/purpose" component={Pages.Purpose} />
          <Route exact path="/history" component={Pages.History} />
          <Route exact path="/contact" component={Pages.Contact} />
          <Route exact path="/results/:word" component={Pages.Results} />
          <Route
            exact
            path="/klirodotima_history"
            component={Pages.KlirodotimaHistory}
          />
          <Route
            exact
            path="/klirodotima_purpose"
            component={Pages.KlirodotimaPurpose}
          />
          <Route exact path="/management" component={Pages.Management} />
          <Route exact path="/scholarships" component={Pages.Scholarships} />
          <Route
            exact
            path="/klirodotima_decisions"
            component={Pages.KlirodotimaDecisions}
          />
          <Route
            exact
            path="/management_decisions"
            component={Pages.ManagementDecisions}
          />
          <Route exact path="/news" component={Pages.News} />
          <Route component={Pages.NotFound} />
        </Switch>
      </Router>
    )
  );
};

export default ExportDefault;
