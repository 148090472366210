import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();
  const devHelper = component.getHelpers("dev");

  const items = component.getData("default.items", []);
  const word = component.getCurrentWord();

  var items_filtered = items.filter(function (item) {
    return item.name.toLowerCase().includes(word.toLowerCase());
  });

  return (
    <React.Fragment>
      <div
        className="mt-2 mb-2 text-center"
        style={{ backgroundColor: "unset" }}
      >
        <div className="container">
          <h3 className="error-title">Αποτελέσματα αναζήτησης για: «{word}»</h3>
        </div>
        {/* End .container */}
      </div>
      {/* End .page-header */}
      <div className="page-content">
        {items_filtered && items_filtered.length !== 0 && (
          <div className="container">
            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="mb-3">
                  <ul>
                    {items_filtered.map((item, index) => (
                      <li
                        key={`item-${devHelper.getObjectValue(item, "slug")}`}
                      >
                        <i
                          className="icon-angle-right mr-2"
                          style={{
                            color: "#3333ff",
                            fontSize: "15px",
                            fontWeight: "600",
                          }}
                        ></i>
                        <a
                          href={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            devHelper.getObjectValue(item, "slug"),
                            "default",
                            "file"
                          )}
                          target="_blank"
                          rel="noreferrer"
                          className="file_link"
                        >
                          {component.ucfirst(
                            devHelper.getObjectValue(item, "name")
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* End .container */}
        {items_filtered && items_filtered.length === 0 && (
          <div
            className="error-content text-center"
            style={{
              backgroundImage: "url(/assets/error-bg.jpg",
              paddingTop: "30px",
              minHeight: "400px",
            }}
          >
            <div className="container">
              <p>Η αναζήτησή σας δεν είχε αποτελέσματα.</p>
              <a href="/" className="btn btn-outline-primary-2 btn-minwidth-lg">
                <span>Επιστροφή στην Αρχική</span>
                <i className="icon-long-arrow-right"></i>
              </a>
            </div>
          </div>
        )}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
