import React from "react";

const ExportDefault = (props) => {
  const { component } = props;

  // const categories = component.getData("default.categories", []);

  return (
    <React.Fragment>
      <div className="mobile-menu-overlay"></div>
      <div className="mobile-menu-container mobile-menu-light">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>
          <form
            onSubmit={(e) => component.checkSearchMobile(e)}
            method="get"
            className="mobile-search"
          >
            <label htmlFor="mobile-search" className="sr-only">
              Aναζήτηση
            </label>
            <input
              type="text"
              className="form-control"
              name="mobile-search"
              id="search_mobile"
              placeholder="Aναζήτηση προϊόντος ..."
            />
            <button
              className="btn btn-primary"
              id="btn-mobile-search"
              type="submit"
            >
              <i className="icon-search"></i>
            </button>
          </form>
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li>
                <a href="/" className="sf-with-ul nav_liks">
                  ΑΡΧΙΚΗ
                </a>

                <ul>
                  <li>
                    <a href="#" className="sf-with-ul">
                      ΒΑΡΒΑΚΕΙΟ ΙΔΡΥΜΑ
                    </a>
                    <ul>
                      <li>
                        <a href="/profile" className="category-name">
                          ΠΡΟΦΙΛ
                        </a>
                      </li>
                      <li>
                        <a href="/purpose" className="category-name">
                          ΣΚΟΠΟΣ
                        </a>
                      </li>
                      <li>
                        <a href="/history" className="category-name">
                          ΙΣΤΟΡΙΑ
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/management" className="category-name">
                      ΔΙΟΙΚΗΣΗ
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav_liks">
                <a href="/management_decisions">ΑΠΟΦΑΣΕΙΣ Δ.Σ.</a>
              </li>
              <li className="nav_liks">
                <a href="/scholarships">ΥΠΟΤΡΟΦΙΕΣ</a>
              </li>
              <li className="nav_liks">
                <a href="/news">ΝΕΑ - ΑΝΑΚΟΙΝΩΣΕΙΣ</a>
              </li>
              <li className="nav_liks">
                <a href="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</a>
              </li>

              <li>
                <a href="#" className="sf-with-ul nav_liks">
                  ΚΛΗΡΟΔΟΤΗΜΑ ΣΑΡΗ
                </a>
                <ul>
                  <li>
                    <a href="/klirodotima_history" className="category-name">
                      ΙΣΤΟΡΙΑ
                    </a>
                  </li>
                  <li>
                    <a href="/klirodotima_purpose" className="category-name">
                      ΣΚΟΠΟΣ
                    </a>
                  </li>
                  <li>
                    <a href="/klirodotima_decisions" className="category-name">
                      ΑΠΟΦΑΣΕΙΣ
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* End .mobile-nav */}

          <div className="social-icons">
            <a
              href="https://www.facebook.com/casadipatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a
              href="https://twitter.com/CasadiPatsi"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-twitter"></i>
            </a>
            <a
              href="https://gr.pinterest.com/casadipatsi/_created/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-pinterest"></i>
            </a>
            <a
              href="https://www.instagram.com/casadipatsi/"
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
          </div>
          {/* End .social-icons */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
