import React from "react";
const ExportDefault = (props) => {
  const { component } = props;
  // const categories = component.getData("default.categories", []);
  const current_page = component.getData("default.page", null);

  return (
    <header className="header header-6">
      <div className="header-middle">
        <div className="container">
          <div className="header-left"></div>
          <div className="header-center">
            <a href="/" className="logo">
              <img
                src="/images/logo.png"
                alt="Logo"
                width="574"
                height="75"
                id="main_logo"
              />
              <img
                src="/images/logo-mobile.png"
                alt="Logo"
                width="300"
                height="75"
                id="mobile_logo"
              />
            </a>
          </div>
          {/* End .header-left */}

          <div className="header-right" style={{ alignItems: "flex-end" }}>
            <div
              className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block"
              style={{ marginRight: "0px" }}
            >
              <a href="/" className="search-toggle" role="button">
                <i className="icon-search"></i>
              </a>
              <form onSubmit={(e) => component.checkSearch(e)} method="get">
                <div
                  className="header-search-wrapper search-wrapper-wide"
                  style={{
                    border: "0.1rem solid #ebebeb",
                    minWidth: "unset",
                    width: "235px",
                  }}
                >
                  <label htmlFor="q" className="sr-only">
                    Aναζήτηση
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="q"
                    id="search"
                    placeholder="Aναζήτηση ..."
                  />
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ marginRight: "15px" }}
                  >
                    <i className="icon-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}

      <div
        className="header-bottom sticky-header"
        style={{ backgroundColor: "#666666" }}
      >
        <div className="container">
          <div className="header-left">
            <nav className="main-nav">
              <ul className="menu sf-arrows">
                <li
                  className={
                    current_page === 0 ? "active nav_liks" : "nav_liks"
                  }
                >
                  <a href="/" className="sf-with-ul">
                    ΑΡΧΙΚΗ
                  </a>
                  <ul>
                    <li>
                      <a href="#" className="sf-with-ul">
                        ΒΑΡΒΑΚΕΙΟ ΙΔΡΥΜΑ
                      </a>
                      <ul>
                        <li>
                          <a href="/profile" className="category-name">
                            ΠΡΟΦΙΛ
                          </a>
                        </li>
                        <li>
                          <a href="/purpose" className="category-name">
                            ΣΚΟΠΟΣ
                          </a>
                        </li>
                        <li>
                          <a href="/history" className="category-name">
                            ΙΣΤΟΡΙΑ
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/management" className="category-name">
                        ΔΙΟΙΚΗΣΗ
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    current_page === 1
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/management_decisions">ΑΠΟΦΑΣΕΙΣ Δ.Σ.</a>
                </li>
                <li
                  className={
                    current_page === 2
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/scholarships">ΥΠΟΤΡΟΦΙΕΣ</a>
                </li>
                <li
                  className={
                    current_page === 3
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/news">ΝΕΑ - ΑΝΑΚΟΙΝΩΣΕΙΣ</a>
                </li>
                <li
                  className={
                    current_page === 4
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/contact">ΕΠΙΚΟΙΝΩΝΙΑ</a>
                </li>
                <li
                  className={
                    current_page === 5 ? "active nav_liks" : "nav_liks"
                  }
                >
                  <a href="#" className="sf-with-ul">
                    ΚΛΗΡΟΔΟΤΗΜΑ ΣΑΡΗ
                  </a>
                  <ul>
                    <li>
                      <a href="/klirodotima_history" className="category-name">
                        ΙΣΤΟΡΙΑ
                      </a>
                    </li>
                    <li>
                      <a href="/klirodotima_purpose" className="category-name">
                        ΣΚΟΠΟΣ
                      </a>
                    </li>
                    <li>
                      <a
                        href="/klirodotima_decisions"
                        className="category-name"
                      >
                        ΑΠΟΦΑΣΕΙΣ
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* End .menu */}
            </nav>
            {/* End .main-nav */}

            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars" style={{ color: "#ffffff" }}></i>
            </button>
          </div>
          {/* End .header-left */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-bottom */}
    </header>
  );
};

export default ExportDefault;
