import React from "react";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="title mb-1">Έχετε οποιαδήποτε ερωτήση;</h2>
              {/* End .title mb-2 */}
              <p className="mb-2">
                Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή
              </p>

              <form
                onSubmit={(e) => {
                  component.sendForm(e);
                }}
                className="contact-form mb-3"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cname" className="sr-only">
                      Όνομα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cname"
                      placeholder="Όνομα *"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το όνομά σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="cemail" className="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      onInvalid={(e) =>
                        e.target.setCustomValidity("Συμπληρώστε το email σας!")
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cphone" className="sr-only">
                      Τηλέφωνο
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Συμπληρώστε το τηλεφωνό σας!"
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="cphone"
                      placeholder="Τηλέφωνο *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="csubject" className="sr-only">
                      Θεμα
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="csubject"
                      placeholder="Θεμα"
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <label htmlFor="cmessage" className="sr-only">
                  Message
                </label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="4"
                  id="cmessage"
                  required
                  placeholder="Τι έχετε στο μυαλό σας;"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Συμπληρώστε το μήνυμά σας!")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                ></textarea>

                <button
                  type="submit"
                  className="btn btn-outline-primary-2 btn-minwidth-sm"
                >
                  <span>ΥΠΟΒΟΛΗ</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </form>
              {/* End .contact-form */}
            </div>
            {/* End .col-lg-6 */}
            <div className="col-lg-6 mb-2 mb-lg-0">
              <h2 className="title mb-1">Στοιχεία Eπικοινωνίας</h2>
              {/* End .title mb-2 */}
              <div id="custom_map" className="map"></div>
              <div className="row">
                <div className="col-sm-7">
                  <div className="contact-info">
                    <ul className="contact-list mt-1">
                      <li style={{ maxWidth: "240px" }}>
                        <i className="icon-map-marker"></i>
                        <a
                          href="https://www.google.com/maps/place/%CE%99%CF%80%CF%80%CE%BF%CE%BA%CF%81%CE%AC%CF%84%CE%BF%CF%85%CF%82+2,+%CE%91%CE%B8%CE%AE%CE%BD%CE%B1+106+79/@37.9819077,23.7338595,19.09z/data=!4m5!3m4!1s0x14a1bd39e16f24df:0x6808b924b25d0839!8m2!3d37.9819589!4d23.7342061"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ιπποκράτους 2 και Ακαδημίας Τ.Κ. 106 79 - Αθήνα
                        </a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+2103632233">+210 3632233</a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+2103618509">+210 3618509</a>
                      </li>
                      <li>
                        <i className="icon-phone"></i>
                        <a href="tel:+2103618186">+210 3618186</a>
                      </li>

                      <li>
                        <i className="icon-envelope"></i>
                        <a href="mailto:varva@otenet.gr">varva@otenet.gr</a>
                      </li>
                    </ul>
                    {/* End .contact-list */}
                  </div>
                  {/* End .contact-info */}
                </div>
                {/* End .col-sm-7 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .col-lg-6 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
