import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="pt-6 pb-5 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="widget-title">
                  <b>Ιστορία:</b>
                </h3>
                <a
                  href="https://www.dropbox.com/s/xzameq7vnccsn54/Lefkoma_150_XRONIA_BARBAKEIO.pdf?dl=0"
                  target="_blank"
                  rel="noreferrer"
                  className="file_link"
                >
                  150 χρόνια Βαρβάκειο. Η σχολική ζωή σε ένα ιστορικό σχολείο
                </a>
                <h3 className="widget-title mt-2">
                  <b>1. Ο εθνικός ευεργέτης Ιωάννης Βαρβάκης</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Ο Ιωάννης Βαρβάκης γεννήθηκε στα Ψαρά γύρω στα 1745. Ήταν
                    γιος του καραβοκύρη Ανδρέα Λεοντή ή Λεοντίδη και η μητέρα
                    του ονομαζόταν Μαρού. Τα μεγάλα μάτια του και ο ορμητικός
                    του χαρακτήρας τον έκαναν να μοιάζει με τα βαρβάκια (είδος
                    γερακιού), γι’ αυτό και τού έδωσαν αυτό το προσωνύμιο που
                    έγινε στη συνέχεια το επώνυμό του.
                  </li>
                  <li className="mb-1">
                    Από πολύ μικρός ο Βαρβάκης ασχολήθηκε με τη ναυτιλία. Στα
                    δέκα του χρόνια είχε μάθει ήδη να πυροβολεί και να
                    χρησιμοποιεί κανόνι, ενώ στα δεκαεπτά του άρχισε να παίρνει
                    μέρος σε επιδρομές εναντίον τουρκικών εμπορικών καραβιών.
                    Είκοσι ετών ήταν κιόλας καπετάνιος με δικό του καράβι, το
                    οποίο χρησιμοποίησε ως πυρπολικό εναντίον των Τούρκων κατά
                    τον ρωσοτουρκικό πόλεμο. Στη ναυμαχία του Τσεσμέ (26 Ιουνίου
                    1770) το καράβι του καταστράφηκε, αλλά ο στρατηγός Αλέξιος
                    Ορλώφ τον τίμησε ονομάζοντάς τον «εθελοντή–ήρωα» και η
                    τσαρίνα Αικατερίνη Β’ τον ενέταξε στις στρατιωτικές δυνάμεις
                    της αυτοκρατορικής Ρωσίας με το βαθμό του υπολοχαγού.
                  </li>
                  <li className="mb-1">
                    Ωστόσο, οι Τούρκοι ποτέ δεν ξέχασαν τις ζημιές που τους
                    προξένησε. Άρχισαν να τον καταζητούν και, επειδή απέτυχαν να
                    τον συλλάβουν, δήμευσαν το καινούργιο του πλοίο. Τότε η
                    ρωσική πρεσβεία τον φυγάδευσε στην Οδησσό, όπου η
                    Αικατερίνη, ως ανταμοιβή για την προσφορά του, του χάρισε
                    10.000 ρούβλια καθώς και το δικαίωμα να κάνει αφορολόγητο
                    εμπόριο ψαριών στην Κασπία θάλασσα. Εγκαταστάθηκε, λοιπόν,
                    στο Αστραχάν, όπου έστησε αμέσως την επιχείρησή του, και,
                    πολύ γρήγορα, κατόρθωσε να πλουτίσει κάνοντας εμπόριο
                    χαβιαριού.
                  </li>
                  <li className="mb-1">
                    Ο Βαρβάκης επέδειξε ιδιαίτερη ευαισθησία σε θέματα παιδείας
                    και πολιτισμού. Στο Αστραχάν, σεβόμενος τα δικαιώματα των
                    μειονοτήτων, πήρε άδεια από τις ρωσικές αρχές να φτιάξει
                    τζαμί κοντά στο εργοστάσιό του, ώστε να μπορούν να
                    προσεύχονται οι μουσουλμάνοι εργάτες του. Επιπλέον, διέθεσε
                    πολλά χρήματα για την υλοποίηση κοινωφελών έργων, αρχικά στο
                    Αστραχάν και στη συνέχεια στο Ταγκανρόγκ, όπου μετακόμισε
                    για λόγους υγείας. Ανάμεσα στα έργα αυτά ήταν και η ίδρυση
                    πολλών σχολείων, παρότι ο ίδιος ήταν αγράμματος.
                  </li>
                  <li className="mb-1">
                    Τις μεγαλύτερες ευεργεσίες του όμως τις έκανε ο Βαρβάκης
                    στην πατρίδα του. Εκτός από τα όσα είχε προσφέρει πριν αλλά
                    και κατά τη διάρκεια της Επανάστασης, με τη διαθήκη του, την
                    οποία ολοκλήρωσε δύο ημέρες πριν πεθάνει στη Ζάκυνθο στις 12
                    Ιανουαρίου 1825, διέθεσε το μεγαλύτερο μέρος της περιουσίας
                    του στην ίδρυση Λυκείου για την εκπαίδευση της ελληνικής
                    νεολαίας, την εξαγορά αιχμαλώτων και τη συντήρηση απόρων
                    οικογενειών σε διάφορα μέρη της Ελλάδας, κυρίως των Ψαριανών
                    προσφύγων μετά την ολοκληρωτική καταστροφή του νησιού το
                    1824. Και βέβαια, πέρα και από τα χρήματα και τα υλικά
                    αγαθά, η πιο σπουδαία παρακαταθήκη που άφησε σε όλους τους
                    Έλληνες και κυρίως στην ελληνική νεολαία είναι η ίδια του η
                    ζωή, η φιλανθρωπία και η γενναιοδωρία του, καθώς και η
                    γνήσια και ανυστερόβουλη φιλοπατρία του.
                  </li>
                </ol>
                <h3 className="widget-title">
                  <b>2.Το «Βαρβάκειον Λύκειον»</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Επτακόσιες χιλιάδες ρούβλια ήταν το ποσό που κληροδότησε ο
                    Ιω. Βαρβάκης στην ελληνική πολιτεία για την ανέγερση
                    διδακτηρίου κατάλληλου να στεγάσει το Βαρβάκειο Λύκειο. Η
                    ελληνική κυβέρνηση όμως καθυστέρησε αρκετά την υλοποίηση του
                    ονείρου του. Άφησε να περάσουν δεκαοκτώ χρόνια μέχρι να
                    αποφασίσει την οικοδόμηση του σχολικού κτηρίου που θα έφερε
                    το όνομά του. Αλλά κι αυτή η απόφαση, που πάρθηκε στις 26
                    Φεβρουαρίου 1843, ανακλήθηκε δεκατρία χρόνια αργότερα, όταν
                    η θέση που είχε επιλεγεί για την ανέγερση του κτηρίου
                    κρίθηκε ακατάλληλη. Τελικά, με βασιλικό διάταγμα της 9ης
                    Ιουνίου 1856, καθορίσθηκε ως ο πλέον κατάλληλος ένας νέος
                    χώρος, ένα οικόπεδο στο κέντρο της Αθήνας που οριζόταν από
                    τις οδούς Αθηνάς, Σωκράτους, Αρμοδίου και Αριστογείτονος.
                  </li>
                  <li className="mb-1">
                    Η θεμελίωση του κτηρίου πραγματοποιήθηκε στις 21 Απριλίου
                    1857 με την παρουσία του βασιλιά Όθωνα. Την εκπόνηση των
                    σχεδίων και την επίβλεψη του έργου ανέλαβε ο αρχιτέκτονας
                    Παναγιώτης Κάλκος, ο οποίος κατόρθωσε, το 1859, να
                    δημιουργήσει ένα εντυπωσιακό νεοκλασικό κτίσμα. Στο κτίσμα
                    αυτό πρωτολειτούργησαν το 1860 ένα «Ελληνικόν Σχολείον»
                    (κατώτερο δευτεροβάθμιο) και ένα «Κλασσικόν Γυμνάσιον»
                    (ανώτερο δευτεροβάθμιο), με τρεις και τέσσερις τάξεις
                    αντιστοίχως, που ονομάστηκαν «Βαρβάκεια». Το 1880 το Κλασικό
                    Γυμνάσιο και το Ελληνικό Σχολείο χωρίστηκαν σε δύο μέρη και
                    έτσι δημιουργήθηκε το Α΄ και Β΄ Βαρβάκειο Γυμνάσιο, καθώς
                    και το Α΄ και το Β΄ Ελληνικό Σχολείο και τα δύο κλασικής
                    κατεύθυνσης.
                  </li>
                  <li className="mb-1">
                    Στις 6 Οκτωβρίου 1886 ιδρύθηκε, με βασιλικό διάταγμα, το
                    «Βαρβάκειον Λύκειον», ένα νέου τύπου σχολείο που ακολουθούσε
                    το πρότυπο των γερμανικών Realgymnasien, δηλαδή των
                    Πραγματικών (=Πρακτικών) Γυμνασίων. Καθοριστικό ρόλο στην
                    ίδρυσή του έπαιξε ο τότε πρωθυπουργός Χαρίλαος Τρικούπης, ο
                    οποίος είχε αντιληφθεί πως η πραγματοποίηση δημόσιων
                    κοινωφελών έργων στο νεοσύστατο ελληνικό κράτος απαιτούσε
                    εγχώριο δυναμικό με επιστημονική και τεχνολογική επάρκεια.
                    Την ιδέα του Τρικούπη για την ίδρυση μιας τέτοιας σχολής,
                    της οποίας βασικός σκοπός θα ήταν η προετοιμασία των νέων
                    για τη μετέπειτα φοίτησή τους στη Σχολή των Βιομηχανικών
                    Τεχνών (το σημερινό Πολυτεχνείο) και τις Στρατιωτικές
                    Σχολές, την υλοποίησε ο διευθυντής και οργανωτής του
                    Πολυτεχνείου Αναστάσιος Θεοφιλάς. Το καινούργιο Λύκειο ήταν
                    επτατάξιο (με ενωμένες τις τρεις τάξεις του Ελληνικού
                    σχολείου και τις τέσσερις του Γυμνασίου), στεγάστηκε αρχικά
                    σ’ ένα μεγάλο κτήριο επί της οδού Βουλής 7 και τα μαθήματά
                    του ξεκίνησαν στο τέλος Οκτωβρίου 1886. Πολύ σύντομα, η φήμη
                    του σχολείου, που διέθετε ειδικό εργαστήριο εφοδιασμένο με
                    όργανα Φυσικής και Χημείας, συλλογές ορυκτών κτλ., ξεπέρασε
                    τα όρια όχι μόνον της πρωτεύουσας αλλά και της χώρας με
                    αποτέλεσμα να συρρέουν σ’ αυτό νέοι από όλη την Ελλάδα και
                    το εξωτερικό.
                  </li>
                  <li className="mb-1">
                    Στην οδό Βουλής το Βαρβάκειο (Πρακτικό) Λύκειο έμεινε για 25
                    ολόκληρα χρόνια, καθώς στο κεντρικό κτίριο της οδού Αθηνάς
                    στεγάζονταν ήδη τα τέσσερα κλασικά δευτεροβάθμια σχολεία και
                    οι προσπάθειες να βρεθεί άλλος χώρος στέγασης σε διάφορα
                    σημεία της πρωτεύουσας δεν καρποφόρησαν. Τελικά, το 1911,
                    αποφασίστηκε η μετακίνηση του Α΄ Βαρβακείου (Κλασικού )
                    Γυμνασίου στο Παγκράτι (ως Ζ΄ Γυμνάσιο Αθηνών) και του Β΄
                    Βαρβακείου (Κλασικού ) Γυμνασίου στα Πατήσια (ως Η΄ Γυμνάσιο
                    Αθηνών), οπότε το Βαρβάκειο (Πρακτικό) Λύκειο εγκαταστάθηκε
                    στο φυσικό του χώρο, στο διδακτήριο, δηλαδή, που είχε
                    κτιστεί με χρήματα προερχόμενα από το κληροδότημα του
                    Βαρβάκη.
                  </li>
                  <li className="mb-1">
                    Το 1915 το κτήριο της οδού Αθηνάς επιτάχθηκε, αρχικά από τον
                    στρατό και αργότερα από τη Χωροφυλακή. Έτσι, οι μαθητές του
                    Βαρβακείου Πρακτικού Λυκείου φιλοξενήθηκαν στα κτήρια άλλων
                    αθηναϊκών σχολείων έως το 1919, οπότε ο καθηγητής Σχεδίου
                    Ζαν Ζακ Πικ, μέλος επιτροπής Γάλλων καθηγητών που τους είχε
                    καλέσει ο πρωθυπουργός Βενιζέλος για να υποβάλλουν προτάσεις
                    σχετικά τη βελτίωση του προγράμματος σπουδών στα πρακτικά
                    λύκεια, πέτυχε την επαναφορά του σχολείου στο κτήριο της
                    οδού Αθηνάς.
                  </li>
                  <li className="mb-1">
                    Τον Ιανουάριο του 1921 το Βαρβάκειο Πρακτικό Λύκειο
                    προσαρτήθηκε στο Διδασκαλείο Μέσης Εκπαίδευσης, το οποίο -
                    ήδη συνδεδεμένο από το 1910 με ένα πρότυπο Ελληνικό σχολείο
                    και ένα πρότυπο κλασικό γυμνάσιο - προσέφερε την παιδαγωγική
                    κατάρτιση που έλειπε από τους υπηρετούντες στα αντίστοιχα
                    σχολεία. Έτσι, το Βαρβάκειο Λύκειο ονομάστηκε και εκείνο
                    Πρότυπο και, επειδή στεγαζόταν πλέον στην οδό Αθηνάς απ’
                    όπου είχαν στο μεταξύ αποχωρήσει και τα δύο Ελληνικά
                    σχολεία, φιλοξένησε στο κτήριό του το Διδασκαλείο μαζί με τα
                    δύο δικά του πρότυπα σχολεία.
                  </li>
                  <li className="mb-1">
                    Το 1929 σημειώθηκαν αλλαγές στη δομή της ελληνικής
                    εκπαίδευσης. Αντί των τριών επάλληλων κύκλων σπουδών
                    (τετρατάξιο Δημοτικό σχολείο, τριτάξιο Ελληνικό σχολείο και
                    τετρατάξιο γυμνάσιο) δημιουργήθηκαν δύο, ένα εξατάξιο
                    Δημοτικό σχολείο και ένα εξατάξιο γυμνάσιο. Τότε,
                    καταργήθηκε το Πρότυπο (Κλασικό) Ελληνικό Σχολείο, το
                    Πρότυπο (Κλασικό) Γυμνάσιο έγινε εξαετές και οι τέσσερις
                    τάξεις του Προτύπου (Πρακτικού) Βαρβακείου Λυκείου πήραν την
                    ονομασία Γ’, Δ’, Ε΄ και ΣΤ’. Το 1930 πάλι, όταν ιδρύθηκαν με
                    νόμο οι Σχολές Μέσης Εκπαίδευσης, οι οποίες ήταν μεν
                    εξατάξιες αλλά είχαν τις τέσσερις ανώτερες τάξεις
                    διαιρεμένες σε δύο τμήματα (κλασικό-πρακτικό), τα χωριστά
                    πρότυπα γυμνάσια ενώθηκαν και δημιουργήθηκε η «Βαρβάκειος
                    Πρότυπος Σχολή Μέσης Εκπαιδεύσεως».
                  </li>
                  <li className="mb-1">
                    Η Βαρβάκειος Πρότυπος Σχολή λειτούργησε κανονικά έως την
                    έναρξη του πολέμου. Κατά τη διάρκειά του, η λειτουργία του
                    σχολείου διακόπηκε. Κι ενώ όλοι οι μαθητές περίμεναν να
                    τελειώσει ο πόλεμος για να επιστρέψουν στα θρανία του
                    κτηρίου της οδού Αθηνάς, μια πυρκαγιά, που εκδηλώθηκε στις
                    28 και 29 Δεκεμβρίου 1944, κατέστρεψε όλα τα ξύλινα μέρη και
                    την υλικοτεχνική υποδομή του σχολείου. Στις φλόγες χάθηκαν
                    όλο το υλικό των βιβλιοθηκών και των εργαστηρίων, τα αρχεία
                    των προτύπων σχολείων, καθώς και τα αρχεία του Διδασκαλείου.
                    Χάθηκε όμως και το νεοκλασικό κτήριο, το οποίο, μολονότι
                    πολλοί ειδικοί είχαν αποφανθεί πως θα μπορούσε να
                    επισκευασθεί, κατεδαφίστηκε τελικά το 1955.
                  </li>
                  <li className="mb-1">
                    Το Διδασκαλείο και η Βαρβάκειος σχολή επαναλειτούργησαν στις
                    αρχές του 1945 τις μεταμεσημβρινές ώρες. Αρχικά
                    φιλοξενήθηκαν και τα δύο στα κτήρια του Δ΄ και του Θ΄
                    Γυμνασίου Αθηνών, στην οδό Μέτωνος και την πλατεία
                    Κουμουνδούρου αντιστοίχως, ενώ το επόμενο σχολικό έτος μόνο
                    το σχολείο μετακινήθηκε στο διδακτήριο του Α΄ και Γ΄
                    Δημοτικού σχολείου Αθηνών, στην οδό Κωλέττη, πάντοτε με
                    απογευματινό ωράριο λειτουργίας.
                  </li>
                  <li className="mb-1">
                    Το 1953, με το αριθμ. 2562/31-8-1953 Νομοθετικό Διάταγμα,
                    ιδρύεται το «Βαρβάκειον Ίδρυμα», στο οποίο περιέρχεται
                    ολόκληρη η περιουσία του κληροδοτήματος Βαρβάκη και το
                    κτήριο της αγοράς μετά της περιοχής αυτού, με τον σκοπό να
                    ιδρυθεί Νέο Εκπαιδευτήριο της Βαρβακείου Προτύπου Σχολής και
                    του Διδασκαλείου Μέσης Εκπαιδεύσεως.
                  </li>
                  <li className="mb-1">
                    Το 1957 παραχωρήθηκε από το κράτος ένα οικόπεδο στο Ψυχικό,
                    στη συμβολή των οδών Μουσών και Παπαδιαμάντη, για την
                    δημιουργία του νέου κτηρίου της Βαρβακείου Σχολής, όπου, το
                    1982, εγκαταστάθηκε πραγματικά το σχολείο. Τρία χρόνια
                    αργότερα, με τον νόμο 1566, η δευτεροβάθμια εκπαίδευση
                    χωρίστηκε σε δύο ανεξάρτητες βαθμίδες (γυμνάσιο-λύκειο), τα
                    πρότυπα καταργήθηκαν και τη θέση πήραν τα πειραματικά
                    σχολεία. Έτσι, από τη «Βαρβάκειο Πρότυπο Σχολή Μέσης
                    Εκπαιδεύσεως» προέκυψαν το Βαρβάκειο Πειραματικό Γυμνάσιο
                    και το Γενικό Πειραματικό Λύκειο της Βαρβακείου Σχολής, τα
                    οποία συνεχίζουν σήμερα την πορεία τους μέσα στο αθηναϊκό
                    εκπαιδευτικό γίγνεσθαι.
                  </li>
                  <li className="mb-1">
                    Από το 2011, με τον Ν. 3986/2011 (ΦΕΚ 118/Α/24-5-2011), το
                    Γυμνάσιο και το Λύκειο της Βαρβακείου Σχολής λειτουργούν ως
                    Πρότυπα Πειραματικά σχολεία.
                  </li>
                </ol>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
