import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="pt-6 pb-5 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="widget-title">
                  <b>Σκοπός:</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Σύμφωνα με το άρθρο 2 του Ν.Δ)τος 2562/1953, σκοπός του
                    Βαρβακείου Ιδρύματος είναι η ίδρυση και λειτουργία
                    εκπαιδευτηρίων της Βαρβακείου Σχολής και του Διδασκαλείου
                    Μέσης Εκπαίδευσης (αντίστοιχος σήμερα θεσμός τα Περιφερειακά
                    Εκπαιδευτικά Κέντρα), καθώς και άλλων Σχολών και η χορήγηση
                    υποτροφιών σε άπορους νέους.
                  </li>
                </ol>
                <h3 className="widget-title">
                  <b>Αποστολή:</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Αποστολή της υπηρεσίας είναι η διαχείριση του κληροδοτήματος
                    του Ιωάννη Βαρβάκη και η εκτέλεση του κοινωφελούς σκοπού
                    του, ώστε να εκπληρώνεται η βούληση του διαθέτη. Επιπλέον το
                    Βαρβάκειο Ίδρυμα διαχειρίζεται ως κεφάλαιο αυτοτελούς
                    διαχείρισης την περιουσία που κατέλειπε σε αυτό, το έτος
                    1973, ο αείμνηστος διακεκριμένος φιλόλογος καθηγητής της
                    Βαρβακείου Σχολής Αλέξανδρος Γ. Σαρής. Σκοπός του
                    κληροδοτήματος Σαρή είναι η βράβευση κατ’ έτος πέντε μαθητών
                    της τελευταίας τάξης του Βαρβακείου Λυκείου, οι οποίοι κατά
                    τη διάρκεια του σχολικού έτους σημείωσαν την καλύτερη
                    επίδοση σε ισάριθμα – καθορισμένα από το διαθέτη και από
                    εφετειακές αποφάσεις - μαθήματα και επέδειξαν διαγωγή
                    «κοσμιοτάτη».
                  </li>
                </ol>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
