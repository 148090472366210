import Helpers from "../../../modules/Core/Helpers/Helpers";
import StockApi from "../../Api/Stock";
import ShareApi from "../../../modules/Api/Share";
import Service from "../Service";

class Content extends Service {
  getSetItems(set, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      ShareApi.content.set(account, repository, set).then((r) => {
        const result = this.evaluate(r, "set-items");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getCategories(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      StockApi.categories(account, repository).then((r) => {
        const result = this.evaluate(r, "categories");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getCategoryItems(slug, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      StockApi.category.items(slug, account, repository).then((r) => {
        const result = this.evaluate(r, "category-items");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getItem(slug, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      StockApi.item(slug, account, repository).then((r) => {
        const result = this.evaluate(r, "item");

        if (result) {
          resolve({ service: this, response: r });
        } else {
          reject(this);
        }
      });
    });
  }

  search(term, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      StockApi.search(term, account, repository).then((r) => {
        const result = this.evaluate(r, "search");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  contact(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      StockApi.contact(account, repository, data).then((r) => {
        const result = this.evaluate(r, "contact");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Content();
