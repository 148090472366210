import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = (props) => {
  return (
    <React.Fragment>
      <Translate
        entity="literal"
        eslug="management-page-content"
        scope="default"
        slug="default"
        auto
      />
    </React.Fragment>
  );
};

export default ExportDefault;
