import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="pt-6 pb-5 mb-lg-8">
          <div className="container">
            <div className="row">
              <div class="col-12">
                <h3 className="widget-title">
                  <b>Ιστορία Κληροδοτήματος Σαρή</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Το Κληροδότημα Αλέξανδρου Γ. Σαρή αποτελεί κεφάλαιο
                    αυτοτελούς διαχείρισης του Βαρβακείου Ιδρύματος, το οποίο
                    διαχειρίζεται την περιουσία που κατέλειπε σε αυτό το έτος
                    1973 ο Αλέξανδρος Γ. Σαρής.
                  </li>
                  <li className="mb-1">
                    <a
                      href={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "alexandros-saris"
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className="file_link"
                      style={{ fontSize: "18px" }}
                    >
                      <b>ΑΛΕΞΑΝΔΡΟΣ Γ.ΣΑΡΗΣ</b>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
