import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 main_banner_desktop ">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "slide-img"
                )}
                alt="img"
                style={{
                  maxHeight: "400px",
                  width: "100%",
                  maxWidth: "1430px",
                }}
              />
            </div>
            <div className="col-12 main_banner_mobile">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "mobile-banner"
                )}
                alt="img"
                style={{ Height: "300px", width: "100%" }}
              />
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <hr className="mt-3 mb-3" />
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="icon-box icon-box-circle text-center">
                <a
                  href="http://gym-peir-athin.att.sch.gr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon-box-icon">
                    <img
                      src="/images/school.png"
                      style={{ width: "50px", height: "50px" }}
                      alt="img"
                    ></img>
                  </span>
                </a>
                <div className="icon-box-content">
                  <a
                    href="http://gym-peir-athin.att.sch.gr/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-box-title"
                  >
                    Βαρβάκειο Πρότυπο Γυμνάσιο
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="icon-box icon-box-circle text-center">
                <a
                  href="http://varvakeio-lykeio.gr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon-box-icon">
                    <img
                      src="/images/school.png"
                      style={{ width: "50px", height: "50px" }}
                      alt="img"
                    ></img>
                  </span>
                </a>
                <div className="icon-box-content">
                  <a
                    href="http://varvakeio-lykeio.gr/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-box-title"
                  >
                    Πρότυπο Γενικό Λύκειο Βαρβακείου Σχολής
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="icon-box icon-box-circle text-center">
                <a
                  href="https://sites.google.com/site/goneisvarvakeiogymnasio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon-box-icon">
                    <img
                      src="/images/family.png"
                      style={{ width: "50px", height: "50px" }}
                      alt="img"
                    ></img>
                  </span>
                </a>
                <div className="icon-box-content">
                  <a
                    href="https://sites.google.com/site/goneisvarvakeiogymnasio/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-box-title"
                  >
                    Σύλλογος Γονέων &amp; Κηδεμόνων Βαρβακείου Προτύπου
                    Γυμνασίου
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="icon-box icon-box-circle text-center">
                <a
                  href="http://goneisvarvakiolykeio.blogspot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="icon-box-icon">
                    <img
                      src="/images/family.png"
                      style={{ width: "50px", height: "50px" }}
                      alt="img"
                    ></img>
                  </span>
                </a>
                <div className="icon-box-content">
                  <a
                    href="http://goneisvarvakiolykeio.blogspot.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-box-title"
                  >
                    Σύλλογος Γονέων &amp; Κηδεμόνων Πρότυπου Γενικού Λυκείου
                    Βαρβακείου Σχολής
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="icon-box icon-box-circle text-center">
                <a href="http://varvakio.gr/" target="_blank" rel="noreferrer">
                  <span className="icon-box-icon">
                    <img
                      src="/images/graduation.png"
                      style={{ width: "50px", height: "50px" }}
                      alt="img"
                    ></img>
                  </span>
                </a>
                <div className="icon-box-content">
                  <a
                    href="http://varvakio.gr/"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-box-title"
                  >
                    Σύλλογος Αποφοίτων Βαρβακείου Σχολής
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-3 mb-3" />
          <div className="row justify-content-center mb-3">
            <div className="portfolio-item col-3">
              <div className="portfolio portfolio-overlay">
                <figure className="portfolio-media">
                  <a
                    href="http://varvakis.varvakeionidryma.gr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "varvakis"
                      )}
                      alt="item"
                      style={{ height: "277px" }}
                    />
                  </a>
                </figure>
                <div className="portfolio-content">
                  <h3 className="portfolio-title">
                    <a
                      href="http://varvakis.varvakeionidryma.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ιωάννης Βαρβάκης
                    </a>
                  </h3>
                  <div className="portfolio-tags">
                    <a
                      href="http://varvakis.varvakeionidryma.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Εργασία μαθητών Βαρβακείου Πρότυπου Γυμνασίου
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-item col-3">
              <div className="portfolio portfolio-overlay">
                <figure className="portfolio-media">
                  <a
                    href="https://www.dropbox.com/s/xzameq7vnccsn54/Lefkoma_150_XRONIA_BARBAKEIO_compressed.pdf?dl=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "lefkoma"
                      )}
                      alt="item"
                      style={{ height: "277px" }}
                    />
                  </a>
                </figure>
                <div className="portfolio-content">
                  <h3 className="portfolio-title">
                    <a
                      href="https://www.dropbox.com/s/xzameq7vnccsn54/Lefkoma_150_XRONIA_BARBAKEIO_compressed.pdf?dl=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Λεύκωμα 150 χρόνια Βαρβάκειο
                    </a>
                  </h3>
                  <div className="portfolio-tags">
                    <a
                      href="https://www.dropbox.com/s/xzameq7vnccsn54/Lefkoma_150_XRONIA_BARBAKEIO_compressed.pdf?dl=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Βαρβάκειον Ίδρυμα
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-item col-3">
              <div className="portfolio portfolio-overlay">
                <figure className="portfolio-media">
                  <a
                    href="http://ilektronikomouseio.varvakeionidryma.gr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "museum"
                      )}
                      alt="item"
                      style={{ height: "277px" }}
                    />
                  </a>
                </figure>
                <div className="portfolio-content">
                  <h3 className="portfolio-title">
                    <a
                      href="http://ilektronikomouseio.varvakeionidryma.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Το Ηλεκτρονικό Μουσείο
                    </a>
                  </h3>
                  <div className="portfolio-tags">
                    <a
                      href="http://ilektronikomouseio.varvakeionidryma.gr/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Βαρβάκειον Ίδρυμα
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-item col-3">
              <div className="portfolio portfolio-overlay">
                <figure className="portfolio-media">
                  <a
                    href="https://et.diavgeia.gov.gr/f/varvakeion_idryma"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "diavgeia"
                      )}
                      alt="item"
                      style={{ height: "277px" }}
                    />
                  </a>
                </figure>
                <div className="portfolio-content">
                  <h3 className="portfolio-title">
                    <a
                      href="https://et.diavgeia.gov.gr/f/varvakeion_idryma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Διαύγεια
                    </a>
                  </h3>
                  <div className="portfolio-tags">
                    <a
                      href="https://et.diavgeia.gov.gr/f/varvakeion_idryma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Βαρβάκειον Ίδρυμα
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
