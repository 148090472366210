import React from "react";

const ExportDefault = (props) => {
  // const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  // const account = envHelper.getDefaultAccount();

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="pt-6 pb-5 mb-lg-8">
          <div className="container">
            <div className="row">
              <div class="col-12">
                <h3 className="widget-title">
                  <b>Σκοπός Κληροδοτήματος Σαρή</b>
                </h3>
                <ol>
                  <li className="mb-1">
                    Σκοπός του κληροδοτήματος Σαρή είναι η βράβευση κατ’ έτος
                    πέντε μαθητών της τελευταίας τάξης του Βαρβακείου Πρότυπου
                    Πειραματικού Λυκείου, οι οποίοι κατά τη διάρκεια του
                    σχολικού έτους σημείωσαν την καλύτερη επίδοση σε ισάριθμα –
                    καθορισμένα από το διαθέτη και από εφετειακές αποφάσεις -
                    μαθήματα και επέδειξαν διαγωγή «κοσμιοτάτη».
                  </li>
                </ol>
              </div>
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
